import React, {useEffect, useState} from 'react';
import {ButtonIcon, Link, Text, View, useDevice} from '@lookiero/aurora';

import {isSeoRender} from '@/modules/SEO';
import {useIntl} from '@/modules/I18N';
import {useHistory} from '@/routes';
import {areCookiesAccepted, setAcceptCookieAgreement} from '@/utils/cookies/cookieAgreement';
import {COOKIES_URL, TEXT} from './CookiesModal.definition';
import {styles} from './CookiesModal.styles';

const CookiesModal: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const {formatMessage} = useIntl();
  const {screen} = useDevice();

  useEffect(() => {
    if (!isSeoRender) setVisible(!areCookiesAccepted());
  }, []);

  const handleAccept = () => {
    setVisible(false);
    history.push(COOKIES_URL);
    setAcceptCookieAgreement();
  };

  const handleHideModal = () => {
    setAcceptCookieAgreement();
    setVisible(false);
  };

  return (
    visible && (
      <View style={[styles.modal, screen.L && styles.modalDesktop]}>
        <Text detail level={2}>
          {formatMessage(TEXT.CONTENT, {
            values: {
              link: (
                <Link detail level={2} onPress={handleAccept} underlined>
                  {formatMessage(TEXT.LINK)}
                </Link>
              ),
            },
          })}
        </Text>
        <ButtonIcon
          style={styles.closeButton}
          onPress={handleHideModal}
          accessibilityLabel={formatMessage(TEXT.CLOSE)}
          name="close"
        />
      </View>
    )
  );
};

export default CookiesModal;
