import StyleSheet from 'react-native-extended-stylesheet';

export const styles = StyleSheet.create({
  modal: {
    bottom: 0,
    left: 0,
    zIndex: '$elevationLayerM',
    position: 'fixed',
    marginBottom: '$space4',
    marginHorizontal: '$space4',
    backgroundColor: '$colorBgSurface',
    color: '$colorText',
    padding: '$space4',
    flexDirection: 'row',
  },
  modalDesktop: {
    width: '25%',
  },
  closeButton: {
    padding: 0,
    paddingLeft: '$space4',
  },
});
