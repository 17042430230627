import {cookie} from '@/utils/cookie';

const COOKIES_AGREEMENT_KEY = 'cookiesAgreement';
const COOKIES_AGREEMENT_EXPIRES = 365;

export const areCookiesAccepted = (): boolean => cookie.get(COOKIES_AGREEMENT_KEY) === 'true';

export const setAcceptCookieAgreement = (): void => {
  cookie.set(COOKIES_AGREEMENT_KEY, 'true', {expires: COOKIES_AGREEMENT_EXPIRES});
};
